import axios from "./axios.js"

// const token = "1|ku7lIj2vaSsk0iYkGbytMxN5FCu6sdojX6fM2fG0";


export class City {

    static getCities() {
        return axios.get("/city");

    }
    static citySearch(query) {
        return axios.post('citysearch', query)
    }
    static cityIdsToName(query) {
        return axios.post('cityids', query)
    }
    static getCity(cityId) {
        let defaultURL = `/city/${cityId}`;
        return axios.get(defaultURL);
    }
    static getCityData(stateId) {
        let defaultURL = `/citydata/${stateId}`;
        return axios.get(defaultURL);
    }
    static createCity(city) {
        let defaultURL = `/city`;
        // return axios.post(defaultURL,city);
        try {
            return axios.post(defaultURL, city);
        } catch (error) {
            console.log("this is error ", error)
            if (error.response.status === 422) {
                return error.response.data.errors

            }
            // console.log(errors.value.name);
        }
    }
    static updateCity(city, cityId) {
        let defaultURL = `/city/${cityId}`;
        return axios.put(defaultURL, city);
    }
    static deleteCity(cityId) {
        let defaultURL = `/city/${cityId}`;
        return axios.delete(defaultURL);
    }

}