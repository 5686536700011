import axios from "./axios.js"

// const token = "1|ku7lIj2vaSsk0iYkGbytMxN5FCu6sdojX6fM2fG0";


export class Group {

    static getGroups() {
        return axios.get("/groups");

    }
    static getGroup(groupId) {
        let defaultURL = `/groups/${groupId}`;
        return axios.get(defaultURL);
    }
    static createGroup(group) {
        let defaultURL = `/groups`;
        // return axios.post(defaultURL,group);
        try {
            return axios.post(defaultURL, group);
        } catch (error) {
            console.log("this is error ", error)
            if (error.response.status === 422) {
                return error.response.data.errors

            }
            // console.log(errors.value.name);
        }
    }
    static updateGroup(group, groupId) {
        let defaultURL = `/groups/${groupId}`;
        return axios.put(defaultURL, group);
    }
    static deleteGroup(groupId) {
        let defaultURL = `/groups/${groupId}`;
        return axios.delete(defaultURL);
    }

}